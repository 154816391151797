import React, { useState } from "react";
import { Select, Space, Layout, Typography, theme, Card ,Button} from "antd";

function App() {
  const { Text } = Typography;
  const { Content } = Layout;
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const [value, setValue] = useState("cloudflare");
  const handleChange = (value: string) => {
    console.log(value);
    setValue(value);
  };
  return (
    <>
      <Layout>
        <Content
          style={{
            margin: "24px 16px",
            background: colorBgContainer,
          }}
        >
          <Card size="small" title="地图源">
            <Space wrap>
              <Select
                defaultValue="cloudflare"
                style={{ width: 180 }}
                onChange={handleChange}
                options={[
                  { value: "cloudflare", label: "Cloudflare" },
                  { value: "cachefly", label: "Cachefly" },
                  { value: "fastly", label: "Fastly" },
                  { value: "gcore", label: "Gcore" },
                  { value: "amazon", label: "Amazon Singapore" },
                ]}
              />
              <Text code copyable>
                {value == "cloudflare"
                  ? "https://mt{0-3}.616611.xyz/vt?lyrs={MapType}&x={x}&y={y}&z={z}&hl=zh-CN&gl=cn"
                  : null}
                {value == "cachefly"
                  ? "https://cachefly.616611.xyz/vt?lyrs={MapType}&x={x}&y={y}&z={z}&hl=zh-CN&gl=cn"
                  : null}
                {value == "fastly"
                  ? "https://fastly.616611.xyz/vt?lyrs={MapType}&x={x}&y={y}&z={z}&hl=zh-CN&gl=cn"
                  : null}
                {value == "gcore"
                  ? "https://gcore.616611.xyz/vt?lyrs={MapType}&x={x}&y={y}&z={z}&hl=zh-CN&gl=cn"
                  : null}
                {value == "amazon"
                  ? "https://sg.616611.xyz/vt?lyrs={MapType}&x={x}&y={y}&z={z}&hl=zh-CN&gl=cn"
                  : null}
              </Text>
              <Button type="primary" onClick={()=>window.open(value+".html")}>测试</Button>
            </Space>
          </Card>
        </Content>
      </Layout>
    </>
  );
}

export default App;
